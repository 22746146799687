import React, { useState } from 'react'
import GenericModal from './GenericModal'
import Wrapper from '../../assets/wrappers/GroupsModal'
import { CgCloseR } from 'react-icons/cg'
import SuccessModal from './SuccessModal'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { RotatingLines } from 'react-loader-spinner'
import userService from '../../services/api/user'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import Quill styles

const ForumModal = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient()
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('') // Rich text state

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  // Handle change for React Quill
  const handleDescriptionChange = (value) => {
    setDescription(value)
  }

  const openSuccessModal = (e) => {
    e.preventDefault()
    setIsSuccessModalOpen(true)
  }
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false)
    onClose()
  }

  const mutation = useMutation({
    mutationFn: userService.addForum,
    onSuccess: (data) => {
      setIsSuccessModalOpen(true)
      queryClient.invalidateQueries(['get-forums'])
    },
    onError: (error) => {
      toast.error('Error')
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!title) {
      toast.error('Title is required')
      return
    }

    if (!description) {
      toast.error('Description is required')
      return
    }

    // If there are no validation errors, submit the form
    mutation.mutate({
      name: title,
      description, // The description is now rich text (HTML format)
    })

    // Clear form inputs
    setTitle('')
    setDescription('')
  }

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <Wrapper>
        <div className='heading'>
          <h3>Create a Forum</h3>
          <CgCloseR onClick={onClose} className='icon' />
        </div>

        <form>
          <p className='note'>
            Fill in details of the forum you want to create, also indicate if
            you want it private or public. Approval for the forum should take a
            maximum of 72 hours.
          </p>
          <div>
            <label>Title of Forum</label>
            <input
              type='text'
              placeholder='Enter title here'
              value={title}
              onChange={handleTitleChange}
            />
            <p className='error'></p>
          </div>

          <div>
            <label>Description (What will the forum be about)</label>
            {/* React Quill for rich text input */}
            <ReactQuill
              value={description}
              onChange={handleDescriptionChange}
              placeholder='Enter description here'
            />
            <p>0/2000</p>
            <p className='error'></p>
          </div>

          <div className='btn'>
            <button onClick={handleSubmit}>
              {mutation.isPending ? (
                <RotatingLines
                  type='Oval'
                  style={{ color: '#FFF' }}
                  height={20}
                  width={20}
                />
              ) : (
                <>Create Forum</>
              )}
            </button>
          </div>
        </form>

        {isSuccessModalOpen && <SuccessModal onClose={closeSuccessModal} />}
      </Wrapper>
    </GenericModal>
  )
}

export default ForumModal
