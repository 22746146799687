import React, { useState } from 'react'
import { MdOutlineCheckBox } from 'react-icons/md'
import groupImg from '../../assets/images/group-img.png'
import { useNavigate } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'

// Function to sanitize the description
const sanitizeDescription = (html) => {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'a']),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      img: ['src', 'alt', 'style'],
      a: ['href', 'target', 'rel'],
    },
  })
}

const MyGroups = ({ groups }) => {
  const [visibleGroups, setVisibleGroups] = useState(4) // Number of initially visible groups
  const navigate = useNavigate()

  const handleLoadMore = () => {
    setVisibleGroups((prevVisibleGroups) => prevVisibleGroups + 4) // Increase by 4 for each load more click
  }

  return (
    <article className='my-groups'>
      <section className='all-groups-section'>
        {groups.slice(0, visibleGroups).map(
          (
            item,
            index // Only map through visible groups
          ) => (
            <div className='content my' key={index}>
              <div className='img img-my'>
                <img src={groupImg} alt={`group-img-${index}`} />
              </div>
              <div className='my-group-content'>
                <h5
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate(`/group/${index}`, { state: { item } })
                  }
                  id='heading'
                >
                  {item.name}
                </h5>
                <p>
                  {item?.privacy === 'public' ? 'All' : item.members?.length}{' '}
                  Members
                </p>

                {/* Render Rich Text Description */}
                <div
                  style={{ color: '#1E1E1E' }}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeDescription(item.description),
                  }}
                />
              </div>
            </div>
          )
        )}
        {visibleGroups < groups.length && ( // Show load more button if there are more groups to display
          <button className='member loadmore group' onClick={handleLoadMore}>
            Load more
          </button>
        )}
      </section>
    </article>
  )
}

export default MyGroups
